
/*
 * This file is used to store constants, including links to each page, in the app. 
 * This allows for easy changes to the app's link structure.
 */

// Poll types (used across the app)
export const REGULAR_POLL_TYPE = "regular"
export const QUADRATIC_POLL_TYPE = "quadratic"
export const RANKED_POLL_TYPE = "ranked"

// Links to each page
export const POLL_DESC_LINK = "/polldesc"

export const APP_LINK = "/app"

export const OWNED_POLLS_LINK = APP_LINK + "/owned"

export const PARTICIPATED_POLLS_LINK = APP_LINK + "/participated"

export const EXPLORE_LINK = APP_LINK + "/explore"

export const MANAGE_VDA_LINK = APP_LINK + "/manage-vda"

export const POLL_LINK = APP_LINK + "/polls"

export const CHOOSE_POLL_TYPE_LINK = APP_LINK + "/choose-poll-type"

export const CREATE_POLL_TYPE_LINK = APP_LINK + "/create-poll"
export const CREATE_POLL_TYPE_LINK_REGULAR = CREATE_POLL_TYPE_LINK + "/" + REGULAR_POLL_TYPE
export const CREATE_POLL_TYPE_LINK_QUADRATIC = CREATE_POLL_TYPE_LINK + "/" + QUADRATIC_POLL_TYPE
export const CREATE_POLL_TYPE_LINK_RANKED = CREATE_POLL_TYPE_LINK + "/" + RANKED_POLL_TYPE

// Create poll pages
export const CREATE_POLL_NAME = "Name"
export const CREATE_POLL_DESC = "Description"
export const CREATE_POLL_OPTIONS = "Options"
export const CREATE_POLL_FEATURES = "Features"
export const CREATE_POLL_COMPLETION = "Completion"